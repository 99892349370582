import auth0 from "auth0-js"
import jwtDecode from "jwt-decode"

const AUTH0_DOMAIN = "douglasconnect.eu.auth0.com"
const AUTH0_CLIENT_ID = "NzB5eEPXuaLv2jDEbga2MQ5cta3SHfqv"
const AUTH0_AUDIENCE = "crackit"
const AUTH0_SCOPE = "openid email profile"

const webStorage = localStorage // localStorage | sessionStorage

var webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  responseType: "token id_token",
  audience: AUTH0_AUDIENCE,
  scope: AUTH0_SCOPE,
  redirectUri: window.location.href
})

function logIn() {
  webAuth.authorize()
}

function logOut() {
  webStorage.removeItem("access_token")
  webStorage.removeItem("id_token")
  webStorage.removeItem("expires_at")
}

function handleAuthentication(success, error) {
  // success: continuation function to call in case of happy hash parsing
  // error: continuation function to call in case of an error in hash parsing
  webAuth.parseHash(function (err, authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      window.location.hash = ""
      setSession(authResult)
      success(userOfAuth(authResult.idToken, authResult.accessToken))
    } else if (err) {
      error(err)
    } else if (isAuthenticated()) {
      success(userOfAuth(webStorage.getItem("id_token"), webStorage.getItem("access_token")))
    }
  })
}

function userOfAuth(idToken, accessToken) {
  const id = jwtDecode(idToken)
  return {
    accessToken: accessToken,
    nickname: id.nickname,
    email: id.email
  }
}

function setSession(authResult) {
  // Set the time that the Access Token will expire at
  const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
  webStorage.setItem("access_token", authResult.accessToken)
  webStorage.setItem("id_token", authResult.idToken)
  webStorage.setItem("expires_at", expiresAt)
}

function isAuthenticated() {
  // Check whether the current time is past the Access Token's expiry time
  const expiresAt = JSON.parse(webStorage.getItem("expires_at"))
  return new Date().getTime() < expiresAt
}

export default {
  logIn: logIn,
  logOut: logOut,
  authenticate: handleAuthentication
}
