import "../styles/main.scss"

import auth from "./auth"
import elmApp from "./elm"
import cytoscape from "./cytoscape"

window.addEventListener('load', function () {
  cytoscape(elmApp)
})

auth.authenticate(
  (auth) => {
    elmApp.ports.receiveIsAuthenticated.send(auth)
  },
  (err) => {
    console.log(err)
    alert('Authentiation error: ' + err.error + '. Check the console for further details.')
    elmApp.ports.receiveIsAuthenticated.send(null)
  }
)

elmApp.ports.logIn.subscribe(() => {
  auth.logIn()
})

elmApp.ports.logOut.subscribe(() => {
  auth.logOut()
})
