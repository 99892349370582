import cytoscape from "cytoscape"
import popper from "cytoscape-popper"
import tippy from "tippy.js"
import dagre from "cytoscape-dagre"

import cytoscapeStyles from "../data/cytoscape/styles.json"
// import cytoscapeStylesAOP from "../data/cytoscape/styles-aop.json"
import cytoscapeElements from "../data/cytoscape/all-v3.json"

cytoscape.use(popper)
cytoscape.use(dagre)


export default function initializeCytoscape(elmApp)
{
  var cy = cytoscape({
    container: document.getElementById("graph"),
    style: cytoscapeStyles,
    // style: cytoscapeStylesAOP,
    elements: cytoscapeElements,
    layout: {
      name: 'preset'
    }
  })


  // // AOP 21 details

  // var aopEdges21 = cy.filter("[AOP=21]");
  // var aopNodes21 = aopEdges21.connectedNodes();
  // var aopEdgesNodes21 = cy.collection().add(aopEdges21).add(aopNodes21);

  // aopEdgesNodes21.absoluteComplement().remove()

  // var layout = cy.elements(aopEdgesNodes21).layout({
  //   name: 'dagre',
  //   rankDir: 'LR',
  //   padding: 20,
  //   align: 'LR'
  // });

  // layout.run();

  // // AOP 150 details

  // var aopEdges150 = cy.filter("[AOP=150]");
  // var aopNodes150 = aopEdges150.connectedNodes();
  // var aopEdgesNodes150 = cy.collection().add(aopEdges150).add(aopNodes150);

  // // C.elegans view

  // var i
  // for (i = 0; i < aopEdgesNodes150.length; i++) {
  //   var node = aopEdgesNodes21[i]
  //   if (node.data('CEL') != "") {
  //     node.addClass("included")
  //   } else {
  //     node.addClass("not-included")
  //   }
  // }

  // aopEdgesNodes150.absoluteComplement().remove()

  // var layout = cy.elements(aopEdgesNodes150).layout({
  //   name: 'dagre',
  //   rankDir: 'LR',
  //   padding: 20,
  //   align: 'LR'
  // });

  // layout.run();


  // var aopEdgesNodes21Json = aopEdgesNodes21.jsons();

  // var cyAOP21 = cytoscape({
  //   container: document.getElementById("graph-aop-21"),
  //   style: cytoscapeStylesAOP,
  //   elements: aopEdgesNodes21Json,
  //   layout: {
  //     name: 'dagre',
  //     rankDir: 'LR',
  //     padding: 20,
  //     align: 'LR'
  //   }
  // })


  // Highlight AOPs

  elmApp.ports.selectAop.subscribe((aop) => {
    cy.batch(() => cy.$("[AOP=" + aop.wikiId + "]").addClass(aop.cssClass))
  })

  elmApp.ports.deselectAop.subscribe((aop) => {
    cy.batch(() => cy.$("[AOP=" + aop.wikiId + "]").removeClass(aop.cssClass))
  })

  // Select species

  elmApp.ports.selectSpecies.subscribe((species) => {
    cy.batch(() => cy.$("[" + species.nameShort + "!='']").addClass("species" + species.nameShort))
  })

  elmApp.ports.deselectSpecies.subscribe((species) => {
    cy.batch(() => cy.$("[" + species.nameShort + "!='']").removeClass("species" + species.nameShort))
  })

  // Highlight node types

  elmApp.ports.selectNodeTypes.subscribe(() => {
    cy.batch(() => cy.nodes().addClass("node-type-visible"))
  })

  elmApp.ports.deselectNodeTypes.subscribe(() => {
    cy.batch(() => cy.nodes().removeClass("node-type-visible"))
  })

  // Show compound perturbation

  elmApp.ports.selectAnalysedCompound.subscribe((compound) => {
    cy.batch(() => cy.nodes().removeClass("perturbed"))
    cy.batch(() => cy.nodes().removeData("compoundDataSmiles"))
    cy.batch(() => cy.nodes().removeData("compoundDataValue"))

    var activeNodes = compound.activatedNodes
    var activeNodesCollection = cy.collection()
    var activeEdges = ""

    if (activeNodes !== null) {
      var i
      for (i = 0; i < activeNodes.length; i++) {
        var node = cy.$("[shared_name='" + activeNodes[i].name + "']")
        node.addClass("perturbed")
        node.data("compoundDataSmiles", compound.smiles)
        node.data("compoundDataValue", activeNodes[i].value)

        activeNodesCollection = activeNodesCollection.add(node);
      }
      // activeEdges = activeNodesCollection.connectedEdges().addClass("perturbed")
    }
  })

  elmApp.ports.deselectAnalysedCompound.subscribe((compound) => {
    cy.batch(() => cy.nodes().removeClass("perturbed"))
    cy.batch(() => cy.nodes().removeData("compoundDataSmiles"))
    cy.batch(() => cy.nodes().removeData("compoundDataValue"))
  })

  // Tooltips for edges and nodes

  var makeTippy = function(node, text) {
    return tippy(node.popperRef(), {
      html: (function() {
        var div = document.createElement('div')
        div.innerHTML = text
        return div
      })(),
      trigger: 'manual',
      interactive: true,
      arrow: true,
      placement: 'bottom',
      hideOnClick: false,
      multiple: false,
      theme: 'network',
      sticky: true,
    }).tooltips[0]
  }

  var species = ["DRE", "HSA", "MMU", "RNO", "CEL"]

  function getEdgeSpeciesReferece(el, species) {
    var confidence = ""

    if (el.data(species + '_Confidence') && el.data(species + '_Confidence') != "") {
      confidence = '<p><b>Confidence:</b> ' + el.data(species + '_Confidence') + '</p>'
    } else {
      confidence = ''
    }

    return (
      '<div class=\"edge-reference\">' +
      '<h4>Reference - <a href=\"https://www.ncbi.nlm.nih.gov/pubmed/' + el.data(species + '_CitationReference') + '/\" target=\"_blank\">' + el.data(species + '_CitationReference') + '</a></h4>' +
      '<p><b>Title:</b> ' + el.data(species + '_CitationComment') + '</p>' +
      '<p><b>Evidence:</b> \"' + el.data(species + '_Evidence') + '\"</p>' +
      confidence +
      '</div>'
    )
  }

  function getEdgeRefereces(el) {
    var references = ""

    var x
    for (x in species) {
      if (el.data(species[x] + '_CitationReference') && el.data(species[x] + '_CitationReference') != "") {
        references += getEdgeSpeciesReferece(el, species[x])
      }
    }

    if (references) {
      return references
    } else {
      return (
        '<p><i>No reference specified</i></p>'
      )
    }
  }

  var tippyContentEdge = function(el) {
    var confidence = ""
    var aop = ""

    if (el.data('annotation_Confidence')) {
      confidence = '<p><b>Confidence:</b> ' + el.data('annotation_Confidence') + '</p>'
    } else {
      confidence = ''
    }

    if (el.data('AOPName') != "") {
      aop = '<hr><p><b>AOP:</b> ' + el.data('AOPName') + ' - <a id="aop-details-link" data-aop-id="' + el.data('AOP') + '">Details</a></p>'
    } else {
      aop = ''
    }

    return (
      '<p class="title is-4 is-spaced">' + el.data('name') + '</p>' +
      '<hr>' + getEdgeRefereces(el) + aop
    )
  }

  var tippyContentNode = function(el) {
    var compoundData = ""
    var celData = ""

    if (el.data('compoundDataValue') && el.data('compoundDataValue') != "") {
      compoundData = '<hr><p><b>Value for compound</b> ' + el.data('compoundDataSmiles') + ': <span class="compound-value">' + el.data('compoundDataValue') + '</span>'
    } else {
      compoundData = ''
    }

    if (el.data('CEL') != "") {
      celData = el.data('CEL') + ' - <span class="has-text-grey">C. elegans</span><br>'
    } else {
      celData = ''
    }


    return (
      '<p class="title is-4 is-spaced">' + el.data('displayName') + '</p>' +
      '<p class="subtitle is-5">' + el.data('NodeTypeL1') + ' (' + el.data('NodeTypeL2') + ')</p>' +
      compoundData +
      '<hr>' + '<p>' +
      el.data('HSA') + ' - <span class="has-text-grey">H. sapiens</span><br>' +
      el.data('MMU') + ' - <span class="has-text-grey">M. musculus</span><br>' +
      el.data('RNO') + ' - <span class="has-text-grey">R. norvegicus</span><br>' +
      el.data('DRE') + ' - <span class="has-text-grey">D. rerio</span><br>' +
      celData +
      '</p>'
    )
  }

  function hideAllTippys() {
    for (const popper of document.querySelectorAll('.tippy-popper')) {
      const instance = popper._tippy

      if (instance.state.visible) {
        instance.popperInstance.disableEventListeners()
        instance.hide()
      }
    }
  }

  cy.on('click', function(event) {
    var el = event.target

    hideAllTippys()

    if (el === cy) {
    } else if (el.isEdge()) {
      var tippy = makeTippy(el, tippyContentEdge(el))
      tippy.show()
      var aopDetailsLink = document.getElementById("aop-details-link")
      if (aopDetailsLink) {
        aopDetailsLink.addEventListener("click", (event) => {
          hideAllTippys()
          elmApp.ports.requestShowAopDetails.send(parseInt(aopDetailsLink.dataset.aopId))
        })
      }
    } else if (el.isNode()) {
      var tippy = makeTippy(el, tippyContentNode(el))
      tippy.show()
    }
  })
}
